import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Obfuscate = makeShortcode("Obfuscate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Imprint`}</h1>
    <p><strong parentName="p">{`Egg² Code Labs e. U.`}</strong><br />{`
Malfattigasse 26/24`}<br />{`
1120 Wien, AT`}<br /></p>
    <br />
Eingetragenes Einzelunternehmen<br />
    <br />
Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik<br />
    <br />
    <p><strong parentName="p">{`E-Mail:`}</strong>{` `}<Obfuscate email="hello@eggsquare.net" mdxType="Obfuscate" /></p>
    <p><strong parentName="p">{`Inhaber:`}</strong>{` Alexander Hofbauer`}<br />{`
`}<strong parentName="p">{`Firmenbuch:`}</strong>{` FN 544993 k`}<br />{`
`}<strong parentName="p">{`Gerichtsstand:`}</strong>{` Wien`}<br />{`
`}<strong parentName="p">{`UID NUmmer:`}</strong>{` ATU78571939`}<br /></p>
    <br />
Mitglied der Wirtschaftskammer Wien, Fachgruppe: Unternehmensberatung, Buchhaltung und Informationstechnik
    <br />
    <br />
Gewerberechtliche Vorschriften: Gewerbeordnung www.ris.bka.gv.at

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      